import { HttpClient } from "@angular/common/http";
import { ErrorHandler, Injectable } from "@angular/core";
import { BehaviorSubject, combineLatest, Observable, of, ReplaySubject} from "rxjs";

import { Customer } from "../models/customer.model";
import { Frame, FrameCollection } from "../models/frame-collection.model";
import { AdminAppEnvironment as environment } from "visenvironment";
import { catchError, finalize, map, shareReplay, tap, withLatestFrom,switchMap } from "rxjs/operators";
import { OAuthService } from "angular-oauth2-oidc";
import { CreateCampaignResponse, RecommendationProgress, } from "../typings/dmt.api-response.types";
import { DMTRecommendation } from "../models/dmt-recommendation.model";
import { DMTCampaignSettings } from "../models/dmt-campaign-settings.model";
import { EditRecommendationDTO, RecommendationFrame, } from "../typings/dmt.ui-dto.types";
import { DMTSettings } from "../models/dmt-settings";
import { Campaign } from "../models/campaignHistory.model";
import { Store } from '../models/store.model';
import { FeatureFlagsService } from "../../handler/featureFlag.service";
@Injectable({
    providedIn: "root",
})
export class CampaignService {
    private readonly mockedCampaignName: string = "mocked_test_campaign_name";

    private readonly opticianIdFlag: string = 'vcld_dmtoid';

    private currentCampaignId: string = "";

    private _avatarThumbnailCache: Map<string, Observable<string>> = new Map<
        string,
        Observable<string>
    >();
    private _frameThumbnailCache: Map<string, Observable<string>> = new Map<
        string,
        Observable<string>
    >();

    public recommendationsSync$: BehaviorSubject<EditRecommendationDTO[]> =
        new BehaviorSubject([]);

    public selectedFrameCollections$: BehaviorSubject<FrameCollection[]> =
        new BehaviorSubject([]);

    public opticianStores$: ReplaySubject<Store[]> = new ReplaySubject(1);

    public currentStore$: BehaviorSubject<Store> = new BehaviorSubject(null);

    public frameSelectFirstAccess: boolean = true;

    public campaignSettingsChanged: boolean = false;

    public reuseRecommendationList: boolean = false;

    public customersCount$: BehaviorSubject<number> = new BehaviorSubject(0);

    public noRecommendationsCount$: BehaviorSubject<number> = new BehaviorSubject(0);

    public isFrameCollectionLoaded$: BehaviorSubject<boolean> = new BehaviorSubject(false);

    public isCampaignTimeset$: BehaviorSubject<boolean> = new BehaviorSubject(true);

    constructor(
        private http: HttpClient,
        private oauth: OAuthService,
        private features: FeatureFlagsService ,
        private errorHandler: ErrorHandler    
        ) {
        this.opticianStores$.next([]);
    }

    public get opticianId() {
        const storedId = localStorage.getItem(this.opticianIdFlag);
        if (storedId) return storedId;

        return this.currentStore$.getValue()?.opticianId;
    }

    public async fetchOpticians(): Promise<void> {
        const uri = environment.connectivity.getStores;
        const opticians = await this.http.get<Store[]>(uri, this.authenticationHeader).toPromise();
        this.opticianStores$.next(opticians);
        
        if(localStorage.getItem(this.opticianIdFlag) === null) {
            this.setOptician(opticians[0]);
        } else {
            const id = localStorage.getItem(this.opticianIdFlag);
            const optician = opticians.find(o => o.opticianId === id);
            if(optician){
                this.setOptician(optician);
            }else{
                this.setOptician(opticians[0]);
            }
        }
    }

    public setOptician(optician: Store): void {
        localStorage.setItem(this.opticianIdFlag, optician?.opticianId);
        this.currentStore$.next(optician);
    }
    private campaignId = () =>
        this.http
            .post<CreateCampaignResponse>(
                environment.connectivity.marketing.createCampaign.replace('{opticianId}', this.opticianId),
                { name: this.mockedCampaignName },
                this.authenticationHeader
            )
            .pipe(
                map((campaign) => campaign.campaignId),
                tap((id) => {
                    this.currentCampaignId = id;
                }),
                shareReplay(1)
            );

    /** Contains the current campaignId | Creates new campaign when empty */
    public campaignId$: Observable<string> = this.campaignId();

    private availableCustomers = () =>
        this.http
            .get<Customer[]>(
                environment.connectivity.marketing.getCustomers.replace('{opticianId}', this.opticianId),
                this.authenticationHeader
            )
            .pipe(
                shareReplay(1)
            );

    /** All available customers */
    public availableCustomers$: Observable<Customer[]> = this.availableCustomers();

    public async fetchCustomersCount(): Promise<void> {
        const count = await this.http
        .get<Customer[]>(
            environment.connectivity.marketing.getCustomers.replace('{opticianId}', this.opticianId),
            this.authenticationHeader
        ).pipe(
            catchError((error) => {
                return of([]);
            }),
            map(customers => {
                return customers.length
            })
        ).toPromise();
        this.customersCount$.next(count);
    }

    private campaignHistory = async () =>
        this.http
            .get<Campaign[]>(
                environment.connectivity.marketing.createCampaign.replace('{opticianId}', this.opticianId),
                this.authenticationHeader
            )
            .pipe(
                shareReplay(1)
            ).toPromise();

    public campaignHistory$: BehaviorSubject<Campaign[]> = new BehaviorSubject<Campaign[]>([]);

    /** Contains the customers selected by the ecp for the digital marketing process */
    public selectedCustomers$: BehaviorSubject<Customer[]> = new BehaviorSubject<Customer[]>([]);

    public opticianEmailAddress$: BehaviorSubject<string> = new BehaviorSubject<string>(null);

    /** Contains all available frames from the ECPs catalogue grouped by collection */
    public frameCollections$: BehaviorSubject<FrameCollection[]> = new BehaviorSubject([]);

    public async fetchFrameCollections() {
        this.isFrameCollectionLoaded$.next(false);
        const collections = await this.http
        .get<FrameCollection[]>(
            environment.connectivity.marketing.getFrames.replace('{opticianId}', this.opticianId),
            this.authenticationHeader
        )
        .pipe(
            tap((collections) => {
                this.isFrameCollectionLoaded$.next(true);
                console.log(
                    "[DMT] [Campaign Service] [Get Frame Collections] received %c" +
                        collections.length +
                        " collections!",
                    "color:green;"
                )
            }),
            shareReplay(1)
        ).toPromise();
        this.frameCollections$.next(collections);
    }

    public allFramesFromCollections$: Observable<Frame[]> =
        this.frameCollections$.pipe(
            map((cs) => {
                let f = [];
                cs.forEach((c) => {
                    f.push(...c.frame);
                });
                return f;
            })
        );

    /** Contains all frameIds selected for recommendation by ECP */
    public selectedFrameIds$: ReplaySubject<string[]> = new ReplaySubject(1);

    public recommendedFrameList$: ReplaySubject<EditRecommendationDTO[]> = new ReplaySubject(1);

    public recommendationsForUI$: Observable<EditRecommendationDTO[]> =
        combineLatest([
            this.selectedCustomers$,
            this.recommendedFrameList$.pipe(/* distinctUntilChanged((x, y) => x.length == y.length) */),
        ]).pipe(map(([customers, recommendations]) => recommendations));

    public campaignSettings$: BehaviorSubject<DMTCampaignSettings> = new BehaviorSubject(null);

    public settings$ = new BehaviorSubject<DMTSettings>(null);

    public fetchRecommendationProgress(): Observable<RecommendationProgress> {
        if (this.currentCampaignId != "") {
            const url =
                environment.connectivity.marketing.recommendationProgress.replace(
                    "{campaignId}",
                    this.currentCampaignId
                );
            return this.http.get<RecommendationProgress>(
                url,
                this.authenticationHeader
            );
        }
    }

    public getAvatarThumbnail(sessionId: string): Observable<string> {
        const entry = this._avatarThumbnailCache.get(sessionId);
        if (!entry) {
            const obj = this.fetchAvatarThumbnail(sessionId);
            this._avatarThumbnailCache.set(sessionId, obj);
            return obj;
        }

        return entry;
    }

    public getFrameThumbnail(frameId: string): Observable<string> {
        const entry = this._frameThumbnailCache.get(frameId);
        if (entry==null) {
            const obj = this.fetchFrameThumbnail(frameId);
            this._frameThumbnailCache.set(frameId, obj);
            return obj;
        }

        return entry;
    }
    private fetchAvatarThumbnail(sessionId: string): Observable<string> {
        const uri =
            environment.connectivity.marketing.getAvatarThumbnail.replace(
                "{sessionId}",
                sessionId
            );
        return this.http
            .get(uri, { ...this.authenticationHeader, responseType: "blob" })
            .pipe(
                catchError((e) => {
                    this.errorHandler.handleError(e);
                    return of(null);
                }),
                map((blob) => {
                    if (blob == null) {
                        return null;
                    }

                    const objectUrl = URL.createObjectURL(blob);
                    return objectUrl;
                }),
                shareReplay(1)
            );
    }

    private  fetchFrameThumbnail(frameId: string): Observable<string> {
        var isCDNEnabled:boolean=this.features.isFeatureFlagEnabled('CDNEnabled'); 
        let frontdoorApiBaseUrl = environment.connectivity.frontdoorApiBaseUrl;
        let replicaFrameThumbnailUrl = environment.connectivity.frontdoorApiBaseUrl ? frontdoorApiBaseUrl + '/adm/marketing/frames/{frameId}/thumbnail' : environment.connectivity.marketing.getFrameThumbnail;
        let frameThumbnailUri= isCDNEnabled ? environment.connectivity.marketing.getFrameThumbnailCDN :  replicaFrameThumbnailUrl
        const uri =
            frameThumbnailUri.replace(
                "{frameId}",
                frameId
            );
          return this.http
            .get(uri, { ...this.authenticationHeader, responseType: "blob" })
            .pipe(
                catchError((e) => {
                    return of(null);
                }),
                switchMap((blob) => {
                    if (blob == null && isCDNEnabled) {
                        const esbUri =
                            replicaFrameThumbnailUrl.replace(
                                "{frameId}",
                                frameId
                            );
                        return this.http
                            .get(esbUri, { ...this.authenticationHeader, responseType: "blob" })
                            .pipe(
                                catchError((e) => {
                                    this.errorHandler.handleError(e);
                                    return of(null);
                                }),
                                map(blob => {
                                    if (blob == null) {
                                        return null
                                    }
                                    else {
                                        const objectUrl = URL.createObjectURL(blob);
                                        return objectUrl;
                                    }
                                }));
                    }
                    else {
                        const objectUrl = URL.createObjectURL(blob);
                        return of(objectUrl);
                    }
                })
            );
    }
    
    private fetchFrameThumbnailFromESB(frameId: string): Observable<string> {
        const uri =
            environment.connectivity.marketing.getFrameThumbnail.replace(
                "{frameId}",
                frameId
            );
        return this.http
            .get(uri, { ...this.authenticationHeader, responseType: "blob" })
            .pipe(
                catchError((e) => {
                    this.errorHandler.handleError(e);
                    return of(null);
                }),
                map((blob) => {
                    if (blob == null) {
                        return null;
                    }

                    const objectUrl = URL.createObjectURL(blob);
                    return objectUrl;
                }),
                shareReplay(1)
            );
    }

    public async fetchDMTSettings(): Promise<void> {
        this.settings$.next(null);
        const settings = await this.http
            .get<DMTSettings>(
                environment.connectivity.marketing.settings.replace('{opticianId}', this.opticianId),
                this.authenticationHeader
            )
            .pipe(shareReplay(1))
            .toPromise();

        this.settings$.next(settings);
    }

    /**
     * Sends the selected customers to the API and starts the recommendation
     */
    public async sendSelectedCustomers(): Promise<void> {
        const uri = `${environment.connectivity.marketing.selectCustomers}/${this.currentCampaignId}`;
        const customerIds: string[] = this.selectedCustomers$
            .getValue()
            .map((c) => c.id);

        await this.http
            .post(uri, [...customerIds], this.authenticationHeader)
            .toPromise();
    }

    public async sendCampaignSettings(
        settings: DMTCampaignSettings
    ): Promise<any> {
        const campaignId = await this.campaignId$.toPromise();
        const uri = `${environment.connectivity.marketing.setRecommendationSettings}/${campaignId}`;
        await this.http
            .put(uri, settings, this.authenticationHeader)
            .toPromise();
    }

    public async sendRecommendationSelection(recommendations): Promise<void> {
        const campaignId = await this.campaignId$.toPromise();
        const uri = `${environment.connectivity.marketing.setSelectedRecommendation}/${campaignId}`;
        await this.http
            .put(uri, recommendations, this.authenticationHeader)
            .toPromise();
    }

    /**
     * preload list of recommendations
     */
    public async fetchRecommendation(): Promise<EditRecommendationDTO[]> {
        const uri = `${environment.connectivity.marketing.selectFrames}/${this.currentCampaignId}`;
        const result: EditRecommendationDTO[] = await this.http
            .get<DMTRecommendation[]>(uri, this.authenticationHeader)
            .pipe(
                withLatestFrom(
                    this.campaignSettings$,
                    this.selectedCustomers$,
                    this.allFramesFromCollections$
                ),
                map(([dmtrs, settings, user, frames]) => {
                    let res: EditRecommendationDTO[] = [];
                    dmtrs.forEach((dmtr) => {
                        let transformed: RecommendationFrame[] =
                            dmtr.frameRecommendations
                                .map((r) =>
                                    frames.find((f) => f.id === r.frameId)
                                )
                                .filter((f) => f != null)
                                .map((fs, i) => {
                                    return {
                                        ...fs,
                                        selected:
                                            i < settings.recommendationAmount &&
                                            i != 0,
                                        inMail: i == 0,
                                        opticianSelected: true,
                                        frsFrame: false,
                                    };
                                });
                            if(dmtr.frameRecommendations.length == 0) {
                                const count = this.noRecommendationsCount$.getValue();
                                this.noRecommendationsCount$.next(count+1);
                            }
                        res.push({
                            frsRecommendation: dmtr.frsRecommendations,
                            allFrames: transformed,
                            sessionId: dmtr.sessionId,
                            recommendationAmount: settings.recommendationAmount,
                            userName: user.find((u) => u.id === dmtr.sessionId)
                                .name,
                        });
                    });
                    return res;
                })
            )
            .toPromise();

        this.recommendedFrameList$.next(result);
        return result;
    }

    public async sendFrameCollectionFilter(
        collections: FrameCollection[]
    ): Promise<void> {
        const uri = `${environment.connectivity.marketing.selectFrames}/${this.currentCampaignId}`;
        const frameIds: string[] = [];

        collections.forEach((c) => {
            const ids = c.frame.map((f) => f.id);
            frameIds.push(...ids);
        });

        this.selectedFrameIds$.next(frameIds);

        const result = await this.http
            .put(uri, frameIds, this.authenticationHeader)
            .toPromise();
    }

    /***
     * Authentication Header for ESB-APIs
     */
    private get authenticationHeader() {
        return {
            headers: {
                Authorization: `Bearer ${this.oauth.getIdToken()}`,
            },
        };
    }

    public cancelCampaign(campaignId: string): Observable<Object> {

        const uri = `${environment.connectivity.marketing.setRecommendationSettings}/${campaignId}`;
        const requestBody = {
            abort: true,
        };
        return this.http.patch(uri, requestBody, this.authenticationHeader);
    }

    public async updateHistory() {
        this.campaignHistory$.next(await this.campaignHistory());
    }

    public async init() {
        this.customersCount$.next(0);
        this.selectedCustomers$ = new BehaviorSubject<Customer[]>([]);
        this.opticianEmailAddress$ = new BehaviorSubject<string>(null);
        this.campaignId$ = this.campaignId();
        this.availableCustomers$ = this.availableCustomers();
        this.campaignHistory$.next(await this.campaignHistory());
        this.campaignSettings$.next(null);
        this.recommendationsSync$.next([]);
        this.selectedFrameCollections$.next([]); 
        this.frameSelectFirstAccess = true;
        this.campaignSettingsChanged = false;
    }
}
