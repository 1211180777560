import { Observable, pipe, BehaviorSubject, combineLatest } from 'rxjs';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AvatarSessionService } from '../../services/admin-avatar-session.service';
import { AvatarsCount } from '../../typings/avatar-creation-session';
import { tap, shareReplay, map, delay } from 'rxjs/operators';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'vis-adm-avatar-session-overview-page',
    templateUrl: './avatar-session-overview.page.component.html',
    styleUrls: ['./avatar-session-overview.page.component.scss']
})
export class AvatarSessionOverviewPageCoponent implements OnInit {

    public isLoading$ = new BehaviorSubject(true);
    public sessionsCount$: Observable<AvatarsCount> = this.sessions.avatarsBySource$.pipe(shareReplay({ refCount: true, bufferSize: 1 }));

    loadedSessionsCount$: Observable<AvatarsCount> = this.sessionsCount$.pipe(
        delay(100),
        tap(x => this.isLoading$.next(false))
    );

    showStoreAvatars$: Observable<boolean> = this.loadedSessionsCount$.pipe(
        map(sessionAvatars => sessionAvatars?.accessedStoreAvatars > 0 || sessionAvatars.unAccessedStoreAvatars > 0)
    );

    showCameronAvatars$: Observable<boolean> = this.loadedSessionsCount$.pipe(
        map(sessionAvatars => sessionAvatars?.accessedCameronAvatars > 0 || sessionAvatars.unAccessedCameronAvatars > 0)
    );

    noAvatarsAvailable$: Observable<boolean> = combineLatest([this.showStoreAvatars$, this.showCameronAvatars$]).pipe(
        map(([showStoreAvatars, showCameronAvatars]) => !showStoreAvatars && !showCameronAvatars && !this.isLoading$.getValue())
    );

    constructor(
        private router: Router,
        private sessions: AvatarSessionService
    ) { }

    async ngOnInit() {
        if(this.sessions.stores$.pipe(map((stores) => stores?.length > 1))) {
            await this.sessions.fetchStores();
        }
    }

    public close() {
        this.router.navigate(['/home']);
    }
}
