import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { OAuthService } from 'angular-oauth2-oidc';
import { Observable } from 'rxjs';
import { SecurityService } from '../services/security.service';


@Injectable()
export class SecurityGuard implements CanActivate {

    constructor(private oAuth: OAuthService, private router: Router, private role: PermissionGuard) {
    }

    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        if (this.oAuth.hasValidIdToken()) {
            // if the route needs an authenticated user only
            if (next.data.permission !== undefined && next.data.permission !== '') {
                return this.role.canActivate(next, state);
            }
            // console.log(`[Security Guard] no permissions needed -> grant_access`);
            return true;
        }

        // console.log(`[Security Guard] no token present -> blocked_access`);
        return this.router.parseUrl('/404-not-found');
    }
}

@Injectable({ providedIn: 'root' })
export class PermissionGuard implements CanActivate {

    constructor(private roles: SecurityService, private router: Router) {
    }

    async canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean | UrlTree> {
        if (next.data.permission) {
            // console.log(`[Permission Guard] Try to route you to the ${next.toString()} Resource with permission request ${next.data.permission}`);
            const hasPermission = await this.roles.canSeeAsync(next.data.permission, true);
            if (!hasPermission) {
                return this.router.parseUrl('/404-not-found');
            }

            return true;
        }

        // console.log('[Permission Guard] [Error] couldn\'t find permission: ' + next.data.permission);
        return this.router.parseUrl('/404-not-found');
    }
}

