<div class="row">
    <div class="col s10 offset-s1 small-page-fix" style="margin-top: 64px">
        <div *ngIf="isLoading" class="table-overlay">
            <mat-spinner mode="indeterminate"></mat-spinner>
        </div>

        <div class="col s12" style="margin-bottom: 48px">
            <p class="adm-title">
                {{ "components.centration-details.title" | translate }}
            </p>
        </div>

        <div class="col s12" style="margin-bottom: 12px">
            <p style="font-size: 14pt; font-weight: bold;">
                Centration Job: {{ getId() }}
            </p>
            <div class="col s6">
                <div class="col s6" *ngIf="centrationDetails$ | async; let details">
                    <p>FrameId: {{details.globalFrameId}}</p>
                    <p>Brand: {{details.brand}}</p>
                    <p>Model: {{details.model}}</p>
                    <p>Size: {{details.size || "-"}}</p>
                    <p>AvatarId: {{(centrationJob$ | async)?.sessionId}}</p>
                    <p *ngIf="centrationJob$ | async; let job">Timestamp: {{job.createdAt | date : 'yyyy-MM-dd HH:mm:ss'}}</p>
                </div>
            </div>

        </div>

        <div class="col s12" style="margin-bottom: 12px">
            <p style="font-size: 14pt; font-weight: bold;">
                Centration Data
            </p>
        </div>

        <div class="col s6" *ngIf="!(centrationResult$ | async)">
            <div class="table-overlay">
                <mat-spinner mode="indeterminate"></mat-spinner>
            </div>
        </div>

        <div class="col s6" *ngIf="centrationResult$ | async; let result">
            <table>
                <thead>
                    <tr>
                        <th>&nbsp;</th>
                        <th class="right">PD</th>
                        <th class="right">x</th>
                        <th class="right">FH</th>
                        <th class="right">Seg. Height</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td class="left">R</td>
                        <td class="right">{{ result.rightCentrationEye.pupilDistance | number: "1.1-2" }}</td>
                        <td class="right">{{ result.rightCentrationEye.x | number: "1.1-2" }}</td>
                        <td class="right">{{ result.rightCentrationEye.y | number: "1.1-2" }}</td>
                        <td class="right">
                            {{ result.rightCentrationEye.h ? (result.rightCentrationEye.h | number: "1.1-2") : "-" }}
                        </td>
                    </tr>
                    <tr>
                        <td class="left">L</td>
                        <td class="right">{{ result.leftCentrationEye.pupilDistance | number: "1.1-2" }}</td>
                        <td class="right">{{ result.leftCentrationEye.x | number: "1.1-2" }}</td>
                        <td class="right">{{ result.leftCentrationEye.y | number: "1.1-2" }}</td>
                        <td class="right">
                            {{ result.leftCentrationEye.h ? (result.leftCentrationEye.h | number: "1.1-2") : "-" }}
                        </td>
                    </tr>
                    <tr>
                        <td class="left">Total</td>
                        <td class="right">{{ result.pupilDistance | number: "1.1-2" }}</td>
                    </tr>
                </tbody>
            </table>

            <table>
                <thead>
                    <tr>
                        <th>&nbsp;</th>
                        <th class="right">BVD</th>
                        <th class="right">FrameFit</th>
                        <th class="right">Diameter</th>
                        <th>&nbsp;</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td class="left">R</td>
                        <td class="right">{{ result.rightCentrationEye.backVertexDistance | number: "1.1-2" }}</td>
                        <td class="right">-</td>
                        <td class="right">-</td>
                        <td class="right">-</td>
                    </tr>
                    <tr>
                        <td class="left">L</td>
                        <td class="right">{{ result.leftCentrationEye.backVertexDistance | number: "1.1-2" }}</td>
                        <td class="right">-</td>
                        <td class="right">-</td>
                        <td class="right">-</td>
                    </tr>
                </tbody>
            </table>

            <div class="col s12" style="margin-bottom: 12px">
                <p style="font-size: 14pt; font-weight: bold;">
                    Frame Data
                </p>
            </div>

            <table>
                <thead>
                    <tr>
                        <th class="right">Panto</th>
                        <th class="right">Wrap</th>
                        <th class="right">A</th>
                        <th class="right">B</th>
                        <th class="right">DBL</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td class="right">{{ result.pantoscopicAngle | number: "1.1-2" }}</td>
                        <td class="right">{{ result.frameData.wrapAngle | number: "1.1-2" }}</td>
                        <td class="right">{{ result.frameData.length | number: "1.1-2" }}</td>
                        <td class="right">{{ result.frameData.height | number: "1.1-2" }}</td>
                        <td class="right">{{ result.frameData.distanceBetweenLenses | number: "1.1-2" }}</td>
                    </tr>
                </tbody>
            </table>

            <div class="col s12">
                <div class="col s6">
                    <p>Centration Rule: Primary position</p>
                    <p>Frame Material: {{result.frameData.frameMaterial || 'Not found'}}</p>
                    <p>Lens Type: Single Vision Standard</p>
                    <p>Uncorrected Pano: {{result.uncorrectedPantoscopicAngle}}</p>
                </div>
                <div class="col s6">
                    <button mat-button class="col s6 secondary" (click)="updateStatus(false)">Reject</button>
                    <button mat-button class="col s6 primary" (click)="updateStatus(true)">Approve</button>
                </div>

            </div>

        </div>

        <!-- <div class="col s6" *ngIf="centrationResult$ | async; let result">
            <p
                style="
                    margin-top: 8px;
                    margin-left: 0%;
                    line-height: 22px;
                    font-size: 16px;
                    font-weight: bold;
                "
            >
                {{ "components.centration-details.table-title" | translate }}
            </p>
            <table class="col s12 mat-table centration-table">
                <tr class="mat-header-row">
                    <th class="mat-header-cell">&nbsp;</th>
                    <th class="mat-header-cell">PD</th>
                    <th class="mat-header-cell">x</th>
                    <th class="mat-header-cell">FH</th>
                    <th class="mat-header-cell">Seg. Height</th>
                </tr>
                <tr class="mat-row">
                    <td class="mat-cell first-cell">R</td>
                    <td class="mat-cell">
                        {{
                            result.rightCentrationEye.pupilDistance
                                | number: "1.1-2"
                        }}
                    </td>
                    <td class="mat-cell">
                        {{ result.rightCentrationEye.x | number: "1.1-2" }}
                    </td>
                    <td class="mat-cell">
                        {{ result.rightCentrationEye.y | number: "1.1-2" }}
                    </td>
                    <td class="mat-cell">
                        {{
                            result.rightCentrationEye.h
                                ? (result.rightCentrationEye.h
                                  | number: "1.1-2")
                                : "-"
                        }}
                    </td>
                </tr>
                <tr class="mat-row">
                    <td class="mat-cell first-cell">L</td>
                    <td class="mat-cell">
                        {{
                            result.leftCentrationEye.pupilDistance
                                | number: "1.1-2"
                        }}
                    </td>
                    <td class="mat-cell">
                        {{ result.leftCentrationEye.x | number: "1.1-2" }}
                    </td>
                    <td class="mat-cell">
                        {{ result.leftCentrationEye.y | number: "1.1-2" }}
                    </td>
                    <td class="mat-cell">
                        {{
                            result.leftCentrationEye.h
                                ? (result.leftCentrationEye.h | number: "1.1-2")
                                : "-"
                        }}
                    </td>
                </tr>
                <tr class="mat-row">
                    <td class="mat-cell first-cell">Total</td>
                    <td class="mat-cell">
                        {{ result.pupilDistance | number: "1.1-2" }}
                    </td>
                </tr>
            </table>

            <table class="col s12 mat-table centration-table">
                <tr class="mat-header-row">
                    <th class="mat-header-cell">&nbsp;</th>
                    <th class="mat-header-cell">BVD</th>
                    <th class="mat-header-cell">FrameFit</th>
                    <th class="mat-header-cell">Diameter</th>
                    <th class="mat-header-cell">&nbsp;</th>
                </tr>
                <tr class="mat-row">
                    <td class="mat-cell first-cell">R</td>
                    <td class="mat-cell">
                        {{
                            result.rightCentrationEye.backVertexDistance
                                | number: "1.1-2"
                        }}
                    </td>
                    <td class="mat-cell">-</td>
                    <td class="mat-cell">-</td>
                    <td class="mat-cell">&nbsp;</td>
                </tr>
                <tr class="mat-row">
                    <td class="mat-cell first-cell">L</td>
                    <td class="mat-cell">
                        {{
                            result.leftCentrationEye.backVertexDistance
                                | number: "1.1-2"
                        }}
                    </td>
                    <td class="mat-cell">-</td>
                    <td class="mat-cell">-</td>
                    <td class="mat-cell">&nbsp;</td>
                </tr>
            </table>

            <table class="col s12 mat-table centration-table">
                <tr class="mat-header-row">
                    <th class="mat-header-cell">Panto</th>
                    <th class="mat-header-cell">Wrap</th>
                    <th class="mat-header-cell">A</th>
                    <th class="mat-header-cell">B</th>
                    <th class="mat-header-cell">DBL</th>
                </tr>
                <tr class="mat-row">
                    <td class="mat-cell">
                        {{ result.pantoscopicAngle | number: "1.1-2" }}
                    </td>
                    <td class="mat-cell">
                        {{ result.frameData.wrapAngle | number: "1.1-2" }}
                    </td>
                    <td class="mat-cell">
                        {{ result.frameData.length | number: "1.1-2" }}
                    </td>
                    <td class="mat-cell">
                        {{ result.frameData.height | number: "1.1-2" }}
                    </td>
                    <td class="mat-cell">
                        {{
                            result.frameData.distanceBetweenLenses
                                | number: "1.1-2"
                        }}
                    </td>
                </tr>
            </table>

            <div
                class="col s8"
                style="justify-content: space-between; display: flex"
            >
                <button (click)="updateStatus(true)" class="col s4" mat-button>
                    Approve
                </button>
                <button
                    (click)="updateStatus(false)"
                    class="col s4"
                    mat-raised-button
                    color="warn"
                >
                    Reject
                </button>
            </div>
        </div> -->

        <div class="col s6">
            <img style="margin-left: 15%" alt=" testImage" src="../../../assets/images/avatar-placeholder.png"
                width="70%" height="auto" />
        </div>
    </div>
</div>
