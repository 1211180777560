import { Component, ErrorHandler } from '@angular/core';

import { tap, map } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';
import { PreferencesService } from '../../services/preferences.service';
import { StoreSettingsEmailsModel } from '../../models/store-setting.email.model';
import { MatDialog } from '@angular/material/dialog';
import { DemoAvatarUploadDialogComponent } from '../../dialogs/demo-avatar-upload/demo-avatar-upload.dialog.component';
import { SecurityService } from '../../services/security.service';
import { UnsavedSettingsChangeDialogComponent } from '../../dialogs/unsaved-changes/unsaved-settings-changes.dialog.component';
import { Router } from '@angular/router';
import { StoreSettingsObjectModel, UploadFileType } from '../../models/store-setting.object.model';
import { CampaignService } from '../../../app-dmt/services/campaign.service';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'vis-adm-store-settings-page',
    templateUrl: './store-settings.page.component.html',
    styleUrls: ['./store-settings.page.component.scss']
})
export class StoreSettingsPageComponent {

    constructor(
        private pref: PreferencesService,
        private campaign: CampaignService,
        private errorHandler: ErrorHandler,
        private dialog: MatDialog,
        private security: SecurityService,
        private router: Router,
    ) { }

    public canSee: boolean = false;

    public panelStateOpen: string[] = [];

    public isLoading: boolean = true;

    private loadingTimer = 500;

    public emailSettings$: Observable<StoreSettingsEmailsModel[]> = this.pref.getEmailsByAgreements().pipe(
        map(list => list.filter(ecp => ecp.id != null)),
        map(list => list.sort((a,b) => a.opticianName.localeCompare(b.opticianName))),
        tap(_ => { setTimeout(() => { this.isLoading = false; }, this.loadingTimer); })
    );

    async ngOnInit() : Promise<void> {
        this.canSee = await this.security.canSeeAsync('admin_full_roleassignments', false); 
        this.pref.saveAllselected$.next(false);
    }

    public saveEmail(emailObject: StoreSettingsEmailsModel) {

        this.isLoading = true;
        this.pref.emailDetails.splice(0, this.pref.emailDetails.length);
        this.pref.setEmailAddress(emailObject).toPromise()
            .then(res => {
                setTimeout(() => {
                 this.emailSettings$ = this.pref.getEmailsByAgreements().pipe(tap(_ => { setTimeout(() => { this.isLoading = false; }, this.loadingTimer); }));
                 this.campaign.fetchOpticians();
                }, this.loadingTimer);
            }).catch(error => {
                this.errorHandler.handleError(error);
                setTimeout(() => { this.isLoading = false; }, this.loadingTimer);
            });
    }

    public getPanelState(opticianId: string):boolean {
        return this.panelStateOpen.includes(opticianId);
    }

    public panelIsOpened(opticianId: string): void {
        if(!this.panelStateOpen.includes(opticianId)){
           this.panelStateOpen.push(opticianId); 
        } 
    }

    public panelIsClosed(opticianId: string): void {
        let index = this.panelStateOpen.indexOf(opticianId);
        if(index != -1){
          this.panelStateOpen.splice(index,1);  
        }
    }

    public demoAvatarUpload():void {
        this.dialog.open(DemoAvatarUploadDialogComponent, {
            width: "600px",
            autoFocus: false,
        });
    }
    public async closeSettingsPage(){
        this.router.navigate(["/home"]);
    }

    public async validateSettingsPage(): Promise<boolean>{
        var bannsettings=  this.pref.bannerSettingsModified$.getValue();
        var pageSettings=  this.pref.settingsModified$.getValue();
        if(this.pref.isMailContentLoading$.getValue()) return false;
        if(bannsettings|| pageSettings)
        {
         const result = await this.dialog.open(UnsavedSettingsChangeDialogComponent, {
             width: '600px',
             autoFocus: false,
           })
             .afterClosed()
             .toPromise();
           if (result) {
               if (this.pref.saveAllselected$.getValue()) {
                   this.isLoading = true;
                   await this.pref
                       .saveALLStoreSettingsDetails()
                       .then((res) => {
                           setTimeout(() => {
                               this.isLoading = false;
                           }, this.loadingTimer);
                       })
                       .catch((error) => {
                           this.errorHandler.handleError(error);
                           setTimeout(() => {
                               this.isLoading = false;
                           }, this.loadingTimer);
                       });
                   this.pref.emailDetails.splice(
                       0,
                       this.pref.emailDetails.length
                   );
               }
               this.pref.settingsModified$.next(false);
               this.pref.resetStoreSettingPageModificationFlag();
               this.pref.bannerSettingsModified$.next(false);
               await this.campaign.fetchOpticians();
               return true;
           } else {
               return false;
           }
        }
        else{
         return true;
        }
    }

}
