import { AvatarSource } from './../../../../../../ConsumerPortal/src/app/vto/models/avatarcreationsession';
import { ActivatedRoute } from '@angular/router';
import { Component, AfterViewInit, ViewChild, OnInit, Renderer2, OnDestroy } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { AvatarCreationSession } from '../../typings/avatar-creation-session';
import { startWith, switchMap, catchError, map, tap } from 'rxjs/operators';
import { of, merge, Observable, combineLatest } from 'rxjs';
import { AvatarSessionService, Store } from '../../services/admin-avatar-session.service';
import { MatDialog } from '@angular/material/dialog';
import { AvatarSessionDeleteDialogComponent } from '../../dialogs/avatar-session-delete/avatar-session-delete.dialog.component';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'vis-adm-session-page',
    templateUrl: './avatar-sessions.page.component.html',
    styleUrls: ['./avatar-sessions.page.component.scss']
})
export class AvatarSessionsPageComponent implements OnInit, AfterViewInit, OnDestroy {

    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;

    public isLoading = true;

    public accessed : SafeUrl = null;

    public dataSrc: AvatarCreationSession[];
    public dataSrcTotal = 0;

    public readonly displayedColumns: string[] = [
        'fullName',
        'id',
        'uploadedAt',
        'action'
    ];

    public multipleStores$: Observable<boolean> = this.avSession.stores$.pipe(
        map(stores => stores.length > 1)
    );

    public selectedStore$ = this.avSession.selectedStore$;
    public stores$ = this.avSession.stores$;
    private avatarSource: string = null;
    public isCameronSource: boolean = false;

    // public data$: Observable<any>;

    constructor(
        public avSession: AvatarSessionService, 
        private dialog: MatDialog, 
        private dom: DomSanitizer,
        private translate: TranslateService,
        private route: ActivatedRoute,
        private renderer:Renderer2
        ) { }
    
    public async ngOnInit() {
        this.avatarSource = this.route.snapshot?.queryParams?.source;

        if (this.avatarSource)
            this.isCameronSource = this.avatarSource.toLowerCase() === AvatarSource.CAMERON.toLowerCase()? true : false;

        if(this.avSession.stores$.pipe(map((stores) => stores?.length === 0))) {
            await this.avSession.fetchStores();
        }
    }

    public async ngAfterViewInit() {
        this.sort.sortChange.subscribe(() => {
            this.paginator.pageIndex = 0;
        });

        // combineLatest([this.avSession.selectedStore$, 
        await this.fetch();

        // this.prepareDownload();
    }

    public async fetch() {
        await merge(this.sort.sortChange, this.paginator.page).pipe(startWith({}), switchMap(() => {
            this.isLoading = true;
            return this.avSession.getAvatarSessions(this.paginator.pageSize, this.paginator.pageIndex, this.sort.active, this.sort.direction, true, this.avatarSource);
        }), map(data => {
            this.dataSrc = data.items;
            this.dataSrcTotal = data.total;
            this.isLoading = false;
            return data.items;
        }), catchError(() => {
            this.isLoading = false;
            return of([]);
        })).toPromise();
    }

    public async changeStore(e) {
        this.paginator.pageIndex = 0;
        this.avSession.selectedStore$.next(e);
        await this.fetch();
    }

    public onDelete(sessionDetails: AvatarCreationSession) {

        this.dialog.open(AvatarSessionDeleteDialogComponent, { data: sessionDetails, width: '50vw', autoFocus: false }).afterClosed().toPromise().then(result => {
            if (result) {

                this.avSession.deleteAvatarSession(sessionDetails.id).pipe(tap((async response => {
                    await this.fetch();
                }))).subscribe();
            }
        });
    }

    // public async prepareDownload() {
    //     this.accessed = await this.avSession.downloadACSFile(true).pipe(map(b => URL.createObjectURL(b)), map(s => this.dom.bypassSecurityTrustUrl(s))).toPromise();
    // }

    downloadAcs() {
        this.avSession.downloadACSFile(true, this.avatarSource)
            .subscribe(blob => {
                const a = this.renderer.createElement('a')
                const objectUrl = URL.createObjectURL(blob)
                a.href = objectUrl
                a.download = `${this.translate.instant("pages.avatar-sessions.accessed")}.csv`;
                a.click();
                URL.revokeObjectURL(objectUrl);
            })
    }

    ngOnDestroy(): void {
        this.avSession.resetStoreSelection();
    }
}
