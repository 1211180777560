import { ErrorHandler, Injectable } from "@angular/core";
import { OAuthService } from "angular-oauth2-oidc";
import { ZeissIdToken, ZeissIdBase } from "visauto-auth";
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { StoreSettingsEmailsModel } from "../models/store-setting.email.model";
import { InvitationMailDatabaseComponent, InvitationMailTemplate } from "../models/invitation-mail-template.model";
import { AdminAppEnvironment as environment } from "visenvironment";
import { catchError, map, shareReplay, tap } from "rxjs/operators";
import { of } from "rxjs/internal/observable/of";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { FileUploadObjectModel, StoreSettingsObjectModel, UploadFileType } from "../models/store-setting.object.model";
import {SettingDetailsSubmitFlagModel,SettingsFlagType } from "../models/store-setting.submitflag.model";
import { HTMLPreview } from "../../app-dmt/services/dmt-email.service";

export enum BinaryType {
    ECP_LOGO,
    DMT_LOGO,
    CUSTOM_BANNER,
    DEFAULT_BANNER
}

@Injectable({ providedIn: "root" })
export class PreferencesService {
    private readonly cacheMap: Map<
        BinaryType,
        Map<string, Observable<string>>
    > = new Map<BinaryType, Map<string, Observable<string>>>([
        [BinaryType.ECP_LOGO, new Map<string, Observable<string>>()],
        [BinaryType.DMT_LOGO, new Map<string, Observable<string>>()],
        [BinaryType.CUSTOM_BANNER, new Map<string, Observable<string>>()],
        [BinaryType.DEFAULT_BANNER, new Map<string, Observable<string>>()],
    ]);
    public settingsModified$: BehaviorSubject<boolean> = new BehaviorSubject(
        false
    );
    public bannerSettingsModified$: BehaviorSubject<boolean> =
        new BehaviorSubject(false);
    public aspectRatioRestriction$: BehaviorSubject<boolean> =
        new BehaviorSubject(false);
    public isMailContentLoading$: BehaviorSubject<boolean> =
        new BehaviorSubject(false);
    public saveAllselected$: BehaviorSubject<boolean> = new BehaviorSubject(
        false
    );
    public emailDetails: StoreSettingsObjectModel[] = [];

    public submitFlagDetails: SettingDetailsSubmitFlagModel[] = [];

    public bannerImagesUploadEnabled: Boolean[] =[];

    constructor(
        private oAuth: OAuthService,
        private http: HttpClient,
        private errorHandler: ErrorHandler
    ) {}

    public get SideNavExpanded(): boolean {
        if (
            localStorage.getItem("vis_expand_sidenav") &&
            this.userName !== "Benjamin [Test] Schulz"
        ) {
            return localStorage.getItem("vis_expand_sidenav") === "true";
        } else {
            return true;
        }
    }

    public set SideNavExpanded(newValue: boolean) {
        localStorage.setItem("vis_expand_sidenav", newValue.toString());
    }

    public getEmailsByAgreements() {
        const url = environment.connectivity.ecp_settings;
        return this.http.get<StoreSettingsEmailsModel[]>(url, {
            headers: { Authorization: `Bearer ${this.oAuth.getIdToken()}` },
        });
    }

    public setEmailAddress(emailObject: StoreSettingsEmailsModel) {
        const url = `${environment.connectivity.ecp_settings}/${emailObject.opticianId}`;

        return this.http.put(url, emailObject, {
            headers: { Authorization: `Bearer ${this.oAuth.getIdToken()}` },
        });
    }
    public updateEmailDetails(
        index: number,
        emailObject: StoreSettingsEmailsModel
    ) {
        if (this.emailDetails[index] != null)
            this.emailDetails[index].storeSettingsEmailModel = emailObject;
        else {
            let storeSettingsObject = new StoreSettingsObjectModel();
            storeSettingsObject.storeSettingsEmailModel = emailObject;
            this.emailDetails[index] = storeSettingsObject;
        }
    }
    public bannerImageUploadFlagUpdate(flag:boolean,index:number)
    {
       this.bannerImagesUploadEnabled[index]=flag;
    }

    public updateFileUploadedDetails(
        index: number,
        UploadedObject: FileUploadObjectModel
    ) {
        if (this.emailDetails[index] != null) {
            if (this.emailDetails[index].FileUploadObjectList != null)
                this.emailDetails[index].FileUploadObjectList[
                    UploadedObject.uploadFileType
                ] = UploadedObject;
            else {
                let FileUploadObject: FileUploadObjectModel[] = [];
                FileUploadObject[UploadedObject.uploadFileType] =
                    UploadedObject;
                this.emailDetails[index].FileUploadObjectList =
                    FileUploadObject;
            }
        } else {
            let storeSettingsObject = new StoreSettingsObjectModel();
            let FileUploadObject: FileUploadObjectModel[] = [];
            FileUploadObject[UploadedObject.uploadFileType] = UploadedObject;
            storeSettingsObject.FileUploadObjectList = FileUploadObject;
            this.emailDetails[index] = storeSettingsObject;
        }
    }
    public setStoreSettingPageModificationFlag(
        settingsType: SettingsFlagType,
        isChanged: boolean
    ) {
        const existingFlag = this.submitFlagDetails.find(
            (s) => s.Flagtype === settingsType
        );
        if (existingFlag) {
            existingFlag.Flag = isChanged;
        } else {
            let storeSettingsObject = new SettingDetailsSubmitFlagModel();
            storeSettingsObject.Flagtype = settingsType;
            storeSettingsObject.Flag = isChanged;
            this.submitFlagDetails.push(storeSettingsObject);
        }

        this.settingsModified$.next(
            this.submitFlagDetails &&
                this.submitFlagDetails?.some((e) => e.Flag)
        );
    }
    public resetStoreSettingPageModificationFlag() {
        this.submitFlagDetails.forEach((e) => (e.Flag = false));
    }
    public setBannerSettingPageModificationFlag(isChanged: boolean) {
        this.bannerSettingsModified$.next(isChanged);
    }
    public invitationDatabaseComponents$ = this.http
        .get<InvitationMailDatabaseComponent[]>(
            environment.connectivity.invitationComponents,
            { headers: { Authorization: `Bearer ${this.oAuth.getIdToken()}` } }
        )
        .pipe(shareReplay(1));

    public getECPLogo(
        opticianId: string,
        origin: "dmt-settings" | "store-settings"
    ) {
        const url = this.getDmtLogoUrl(origin, opticianId);

        let type =
            origin == "dmt-settings"
                ? BinaryType.DMT_LOGO
                : BinaryType.ECP_LOGO;
        const binaryMap = this.cacheMap.get(type);
        const binaryUrl = binaryMap.get(opticianId);

        if (binaryUrl == null) {
            const obs = this.http
                .get(url, {
                    headers: {
                        Authorization: `Bearer ${this.oAuth.getIdToken()}`,
                    },
                    responseType: "blob",
                })
                .pipe(
                    catchError((e) => {
                        if (e instanceof HttpErrorResponse) {
                            if (e.status != 404) {
                                this.errorHandler.handleError(e);
                            }
                        }
                        return of(null);
                    }),
                    map((blob) => {
                        if (blob == null) {
                            return null;
                        }

                        const objUrl = URL.createObjectURL(blob);
                        return objUrl;
                    })
                );
            binaryMap.set(opticianId, obs);
            return obs;
        }

        return binaryUrl;
    }

    public removeECPLogo(
        opticianId: string,
        origin: "dmt-settings" | "store-settings"
    ) {
        const url = this.getDmtLogoUrl(origin, opticianId);
        return this.http
            .delete(url, {
                headers: { Authorization: `Bearer ${this.oAuth.getIdToken()}` },
            })
            .pipe(
                tap(() => {
                    const type =
                        origin === "dmt-settings"
                            ? BinaryType.DMT_LOGO
                            : BinaryType.ECP_LOGO;
                    const binaryMap = this.cacheMap.get(type);
                    binaryMap.delete(opticianId);
                })
            );
    }

    public setECPLogo(
        file: File,
        opticianId: string,
        origin: "dmt-settings" | "store-settings"
    ) {
        const form = new FormData();
        form.append("content", file);
        const url = this.getDmtLogoUrl(origin, opticianId);

        return this.http
            .put(url, form, {
                headers: { Authorization: `Bearer ${this.oAuth.getIdToken()}` },
            })
            .pipe(
                tap(() => {
                    let type =
                        origin == "dmt-settings"
                            ? BinaryType.DMT_LOGO
                            : BinaryType.ECP_LOGO;
                    const binaryMap = this.cacheMap.get(type);
                    binaryMap.set(
                        opticianId,
                        of(URL.createObjectURL(file)).pipe(shareReplay(1))
                    );
                })
            );
    }

    private get userName() {
        const claims = this.oAuth.getIdentityClaims() as ZeissIdToken;
        if (claims) {
            const base = JSON.parse(claims.ZeissIdBase) as ZeissIdBase;
            return `${base.firstName} ${base.lastName}`;
        }
        return null;
    }

    private getDmtLogoUrl(
        origin: "store-settings" | "dmt-settings",
        opticianId: string
    ) {
        let url = "";
        if (origin === "store-settings") {
            url = `${environment.connectivity.coBrandingLogo}/${opticianId}`;
        }
        if (origin === "dmt-settings") {
            url = environment.connectivity.marketing.getDMTLogo.replace(
                "{opticianId}",
                opticianId
            );
        }

        return url;
    }

    public async getNewInvitationMailTemplate(
        opticianId: string,
        language: string
    ): Promise<InvitationMailTemplate> {
        let url = `${environment.connectivity.invitationTemplates.replace(
            "{opticianId}",
            opticianId
        )}?lang=${language}`;
        try {
            const imt = await this.http
                .get<InvitationMailTemplate[]>(url, {
                    headers: {
                        Authorization: `Bearer ${this.oAuth.getIdToken()}`,
                    },
                })
                .toPromise();
            return imt.pop();
        } catch (ex) {
            return null;
        }
    }

    public async saveInvitationTemplate(
        template: InvitationMailTemplate
    ): Promise<void> {
        let url = environment.connectivity.invitationTemplates.replace(
            "{opticianId}",
            template.opticianId
        );
        await this.http
            .post(url, template, {
                headers: { Authorization: `Bearer ${this.oAuth.getIdToken()}` },
            })
            .toPromise();
        return;
    }

    public async sendInvitationEmailForPreview(
        template: InvitationMailTemplate
    ): Promise<string> {
        const url = environment.connectivity.previewInvitationTemplate.replace(
            "{opticianId}",
            template.opticianId
        );
        const r = await this.http
            .post<HTMLPreview>(url, template, {
                headers: { Authorization: `Bearer ${this.oAuth.getIdToken()}` },
            })
            .toPromise();
        const text = r.preview;
        return text;
    }

    public getBannerImage(kind: "custom" | "default", opticianId?: string) {
        const url = this.getBannerUrl(opticianId);

        let type =
            kind == "custom"
                ? BinaryType.CUSTOM_BANNER
                : BinaryType.DEFAULT_BANNER;
        let optician = kind == "custom" ? opticianId : "default";
        const binaryMap = this.cacheMap.get(type);
        const binaryUrl = binaryMap.get(optician);

        if (binaryUrl == null) {
            const obs = this.http
                .get(url, {
                    headers: {
                        Authorization: `Bearer ${this.oAuth.getIdToken()}`,
                    },
                    responseType: "blob",
                })
                .pipe(
                    catchError((e) => {
                        if (e instanceof HttpErrorResponse) {
                            if (e.status != 404) {
                                this.errorHandler.handleError(e);
                            }
                        }
                        return of(null);
                    }),
                    map((blob) => {
                        if (blob == null) {
                            return null;
                        }

                        const objUrl = URL.createObjectURL(blob);
                        return objUrl;
                    })
                );
            binaryMap.set(optician, obs);
            return obs;
        }

        return binaryUrl;
    }

    public removeCustomBanner(opticianId: string) {
        const url = this.getBannerUrl(opticianId);
        return this.http
            .delete(url, {
                headers: { Authorization: `Bearer ${this.oAuth.getIdToken()}` },
            })
            .pipe(
                tap(() => {
                    const type = BinaryType.CUSTOM_BANNER;
                    const binaryMap = this.cacheMap.get(type);
                    binaryMap.delete(opticianId);
                })
            );
    }

    public setCustomBanner(file: File, opticianId: string) {
        const form = new FormData();
        form.append("content", file);
        const url = this.getBannerUrl(opticianId);

        return this.http
            .post(url, form, {
                headers: { Authorization: `Bearer ${this.oAuth.getIdToken()}` },
            })
            .pipe(
                tap(() => {
                    let type = BinaryType.CUSTOM_BANNER;
                    const binaryMap = this.cacheMap.get(type);
                    binaryMap.set(
                        opticianId,
                        of(URL.createObjectURL(file)).pipe(shareReplay(1))
                    );
                })
            );
    }

    private getBannerUrl(opticianId?: string): string {
        let url = "";
        if (opticianId) {
            url = `${environment.connectivity.customBanner}`.replace(
                "{opticianId}",
                opticianId
            );
        } else {
            url = environment.connectivity.getDefaultBanner;
        }

        return url;
    }
    public async saveALLStoreSettingsDetails(): Promise<Boolean> {
        for(let i= 0;i<this.emailDetails.length;i++ )
        {
            await this.saveStoreSettingsDetails(this.emailDetails[i],i).catch((error) => {
                this.errorHandler.handleError(error);
                return false;
            });
        }
        return true;
    }
    public async saveStoreSettingsDetails(
        StoreSettingsObject: StoreSettingsObjectModel,index:number
    ): Promise<Boolean> {
        if (StoreSettingsObject?.storeSettingsEmailModel != null) {
            if (
                StoreSettingsObject?.storeSettingsEmailModel
                    ?.customInvitationEnabled &&
                StoreSettingsObject?.storeSettingsEmailModel
                    ?.customInvitation != null
            )
            await  this.saveInvitationTemplate(
                    StoreSettingsObject.storeSettingsEmailModel.customInvitation
                );
            await this.setEmailAddress(
                StoreSettingsObject.storeSettingsEmailModel
            ).toPromise();
        }
        if (StoreSettingsObject?.FileUploadObjectList != null) {
            for (let data of StoreSettingsObject?.FileUploadObjectList) {
                if (data?.uploadfile != null && data?.uploadfile != undefined) {
                    if (data?.uploadFileType == UploadFileType.LogoInput)
                        await this.setECPLogo(
                            data?.uploadfile,
                            data.opticianId,
                            "store-settings"
                        ).toPromise();
                    else if (
                        data?.uploadFileType == UploadFileType.BannerInput &&
                        this.bannerImagesUploadEnabled[index]
                    )
                        await this.setCustomBanner(
                            data?.uploadfile,
                            data.opticianId
                        ).toPromise();
                }
            }
        }
        return true;
    }
}



