import { Component, OnInit } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatDialog } from '@angular/material/dialog';
import { AdminAppEnvironment as environment } from 'visenvironment';
import { AboutDialogComponent } from '../about/about.dialog.component';

// import { SharedDebuggerService } from 'shared';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'vis-faq-page',
    templateUrl: './faq.page.component.html',
    styleUrls: ['./faq.page.component.scss']
})
export class FAQDialogComponent implements OnInit {

    private opened: Array<string> = [];

    constructor(private bottom: MatBottomSheet, private dialogHost: MatDialog) { }

    ngOnInit(): void { }

    // Workaround for angular component issue #13870
    disableAnimation = true;
    ngAfterViewInit(): void {
        // timeout required to avoid the dreaded 'ExpressionChangedAfterItHasBeenCheckedError'
        setTimeout(() => this.disableAnimation = false);
    }

    public get versionNumber() {
        return environment.version.split('+').shift();
    }

    public getCSS(id: string): string {
        if (this.opened.includes(id)) {
            return 'selected';
        }

        return '';
    }

    public openPanel(id: string) {
        if (!this.opened.includes(id)) {
            this.opened.push(id);
        } else {
            console.warn(`the selected id (${id}) is already in list!`);
        }
    }

    public closePanel(id: string) {
        if (this.opened.includes(id)) {
            this.opened.splice(this.opened.findIndex(s => s === id), 1);
        } else {
            console.warn(`the selected id (${id}) is not in list!`);
        }
    }

    public openSupport() {
        window.open(environment.contact.form_stack, '_blank', 'location=no');
    }

    public openAbout() {

        // Small Devices
        if (window.innerWidth < 993) {
            this.dialogHost.open<AboutDialogComponent>(AboutDialogComponent, { width: '99vw' });
        }
        // Medium Devices
        else if (window.innerWidth >= 993 && window.innerWidth < 1201) {
            this.dialogHost.open<AboutDialogComponent>(AboutDialogComponent, { width: '50vw' });
        }
        // Large Devces
        else if (window.innerWidth >= 1201) {
            this.dialogHost.open<AboutDialogComponent>(AboutDialogComponent, { width: '33vw' });
        }
    }
}
