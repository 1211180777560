import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'jobType' })
export class JobTypePipe implements PipeTransform {

    private readonly icons = ['touch_app', 'assistant']

    transform(value: number): string {
        return value === 0 ? 'assistant' : value === 1 ? 'touch_app' : 'INVALID';
        return value === 0 ? 'AUTOMATED' : value === 1 ? 'INTERACTIVE' : 'INVALID';
    }
}
