import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';

@Component({
    selector: 'vcld-adm-size-input',
    templateUrl: './size-input.component.html',
    styleUrls: ['./size-input.component.scss']
})

export class SizeInputComponent implements OnInit {


  @Input()
  public enabled: boolean = false;

  @Input()
  public disabled: boolean = false;

  @Output()
  public onChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  public ngOnInit() {

  }

    public onCheckboxChanged(size: MatCheckboxChange): void {
      this.onChange.emit(size.checked);
  }

}